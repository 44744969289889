import "./contact.css";
import Container from "react-bootstrap/Container";
import { Link } from "react-router-dom";

export default function Contact() {
  return (
    <section className="contact">
      <div className="container2">
        <Container className="mx-auto" style={{ maxWidth: "90%" }}>
          <div className="cont">
            <div className="r-mesh">
              <img
                className="r-star"
                src="http://dojoitsolutions.com/wp-content/uploads/2024/08/Star-1-1.svg"
              ></img>
              <img
                className="r-rectangle"
                src="http://dojoitsolutions.com/wp-content/uploads/2024/08/Rectangle-36.svg"
              ></img>
              <img
                className="r-polygon1"
                src="http://dojoitsolutions.com/wp-content/uploads/2024/08/Polygon-1-1.svg"
              ></img>
              <img
                className="r-rpolygon2"
                src="http://dojoitsolutions.com/wp-content/uploads/2024/08/Polygon-2-1.svg"
              ></img>
              <img
                className="r-ellipse5"
                src="http://dojoitsolutions.com/wp-content/uploads/2024/08/Ellipse-5-2.svg"
              ></img>
              <img
                className="r-ellipse6"
                src="http://dojoitsolutions.com/wp-content/uploads/2024/08/Ellipse-6-1.svg"
              ></img>
            </div>
            <p>Let’s Talk</p>
            <h1>Ready to Grow Your Business?</h1>
            <Link to={"/contact"}>
              <button>CONTACT US</button>
            </Link>
          </div>
        </Container>
      </div>
    </section>
  );
}
