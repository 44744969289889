import { useState, useEffect } from "react";
import "./header.css";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { Link } from "react-router-dom";

function Counter({ start, end, speed }) {
  const [count, setCount] = useState(start);

  useEffect(() => {
    const interval = setInterval(() => {
      if (count < end) {
        setCount(count + 1);
      } else {
        clearInterval(interval);
      }
    }, speed);

    return () => clearInterval(interval);
  });

  return (
    <h1>
      {count}
      <sup>+</sup>
    </h1>
  );
}
export default function Header() {
  return (
    <header>
      <Container className="mx-auto" style={{ maxWidth: "90%" }}>
        <div className="container2">
          <div className="row">
            <div className="left col-md-6 col-sm-12 col-lg-6 ">
              <h1>WE CAN </h1>
              <h1>TRANSFORM EVERY</h1>
              <h1>DIGITAL PROCESS</h1>
              <p className="p subtitleHero">
                IT solutions for all your business needs under one roof.
              </p>
              <p className="subtitleHero">Ready to create an impact?</p>
              <Link to={"/contact"}>
                <button>Get Started Now</button>
              </Link>
            </div>
            <div className="miniani">
              <img
                src="https://dojoitsolutions.com/wp-content/uploads/2024/09/homemini.svg"
                alt=""
              />
            </div>
            <div className="right col-md-6 col-sm-12 col-lg-6 ">
              <div className="test">
                <img
                  className="lines"
                  src="http://dojoitsolutions.com/wp-content/uploads/2024/08/lines.svg"
                  alt=""
                />
                <img
                  className="wifi"
                  src="http://dojoitsolutions.com/wp-content/uploads/2024/08/wifi.svg"
                  alt=""
                />
                <img
                  className="lap"
                  src="http://dojoitsolutions.com/wp-content/uploads/2024/08/laptop.svg"
                  alt=""
                />
                <img className="data" src="/imgs/data.svg" alt="" />
                <img
                  className="data"
                  src="http://dojoitsolutions.com/wp-content/uploads/2024/08/data.svg"
                  alt=""
                />
                <img
                  className="mobile"
                  src="http://dojoitsolutions.com/wp-content/uploads/2024/08/mobile.svg"
                  alt=""
                />
                <img
                  className="serv"
                  src="http://dojoitsolutions.com/wp-content/uploads/2024/08/server.svg"
                  alt=""
                />
                <img
                  className="colm"
                  src="http://dojoitsolutions.com/wp-content/uploads/2024/08/columns-1.svg"
                  alt=""
                />
              </div>
            </div>
          </div>
          <div className="bar">
            <div className="d-flex start">
              <h1>
                <Counter start={0} end={20} speed={40} />
              </h1>
              <p>clients served</p>
            </div>
            <img
              className="line1"
              src="https://dojoitsolutions.com/wp-content/uploads/2024/09/Line-1.svg"
              alt=""
            />
            <div className="d-flex">
              <h1>
                <Counter start={0} end={100} speed={10} />
              </h1>
              <p>projects completed</p>
            </div>
            <img
              className="line2"
              src="https://dojoitsolutions.com/wp-content/uploads/2024/09/Line-1.svg"
              alt=""
            />
            <div className="d-flex end">
              <h1>
                <Counter start={0} end={3} speed={80} />
              </h1>
              <p>products created</p>
            </div>
          </div>
        </div>
      </Container>
    </header>
  );
}
