import React from "react";
import Container from "react-bootstrap/Container";
import { motion, useInView } from "framer-motion";
import "./section4.css";

export default function Section4() {
  const ref = React.useRef(null);
  const isInView = useInView(ref, { once: false, amount: 0.3 });

  return (
    <section className="section4" ref={ref} id="zeal">
      <div className="overlay"></div>
      <div className="container2">
        <Container className="mx-auto" style={{ maxWidth: "90%" }}>
          <motion.img
            className="mobilelink4"
            src="https://dojoitsolutions.com/wp-content/uploads/2024/10/zeal11.png"
            alt=""
            initial={{ opacity: 0, x: 200 }}
            animate={{ opacity: isInView ? 1 : 0, x: isInView ? 0 : 200 }}
            transition={{ duration: 0.5, delay: 0.2 }}
          />
          <motion.div
            className="mainTitle4"
            initial={{ opacity: 0, x: -200 }}
            animate={{ opacity: isInView ? 1 : 0, x: isInView ? 0 : -200 }}
            transition={{ duration: 0.1 }}
          >
            <h2>Zeal Fitness</h2>
            <p>Experience a personalized fitness journey with Zeal Fitness.</p>
            {/* <img
              className="red"
              src="https://dojoitsolutions.com/wp-content/uploads/2024/09/blueH.svg"
              alt=""
            /> */}
          </motion.div>
          <motion.div
            className="subTitle4"
            initial={{ opacity: 0, x: -200 }}
            animate={{ opacity: isInView ? 1 : 0, x: isInView ? 0 : -200 }}
            transition={{ duration: 0.1, delay: 0.2 }}
          >
            <p>
              Zeal Fitness is a comprehensive fitness app designed to help users
              achieve their health goals. With personalized workout plans,
              progress tracking, and a supportive community, Zeal provides
              everything you need to stay motivated and on track.
            </p>
            <a>DOWNLOAD APP</a>
          </motion.div>
          <div className="dividerPortfolio2">
            <img
              className="dividerPortfolio"
              src="https://dojoitsolutions.com/wp-content/uploads/2024/10/newblue.svg"
              alt=""
            />
          </div>
        </Container>
      </div>
    </section>
  );
}
