import HeroServicesPage from "../components/heroServicesPage/HeroServicesPage";
import Nav from "../components/navbar/Nav";
import ServicesCards from "../components/servicesCards/ServicesCards";
import Contact from "../components/contact/Contact";
import Footer from "../components/footer/Footer";
import { useRef, useEffect, useState } from "react";
import { Link } from "react-router-dom";
export default function Services() {
  const [showScrollBTN, setshowScrollBTN] = useState(false);
  const [clickShowScrollBTN, setClickShowScrollBTN] = useState(false);
  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.scrollY > 300) {
        setshowScrollBTN(true);
      } else {
        setshowScrollBTN(false);
      }
    });
  }, []);
  const myWepRef = useRef(null);
  useEffect(() => {
    myWepRef.current?.scrollIntoView({ behavior: "smooth" });
  }, []);
  const myWepRef2 = useRef(null);
  useEffect(() => {
    myWepRef2.current?.scrollIntoView({ behavior: "smooth" });
  }, [clickShowScrollBTN]);
  return (
    <div ref={myWepRef2}>
      <div ref={myWepRef}>
        <Nav />
        <HeroServicesPage />
        <div className="divider1">
          <div className="line"></div>
          <span></span>
        </div>
        <ServicesCards />
        <div className="divider3">
          <div className="line"></div>
          <span></span>
        </div>
        <Contact />
        <div className="divider4">
          <div className="line"></div>
          <span></span>
        </div>
        <Footer />
        <Link
          onClick={() => {
            if (clickShowScrollBTN) {
              setClickShowScrollBTN(false);
            } else {
              setClickShowScrollBTN(true);
            }
          }}
          style={{ opacity: showScrollBTN ? 1 : 0, transition: "0.7s" }}
        >
          <button className="icon-chevron-upp">
            <img
              src="https://dojoitsolutions.com/wp-content/uploads/2024/10/arrow.svg"
              alt=""
            />
          </button>
        </Link>
      </div>
    </div>
  );
}
