import { useState, useEffect } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import {
  Navigation,
  Autoplay,
  Pagination,
  Scrollbar,
  A11y,
} from "swiper/modules";

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import Container from "react-bootstrap/Container";
import "./mainAbout.css";
function Counter({ start, end, speed }) {
  const [count, setCount] = useState(start);

  useEffect(() => {
    const interval = setInterval(() => {
      if (count < end) {
        setCount(count + 1);
      } else {
        clearInterval(interval);
      }
    }, speed);

    return () => clearInterval(interval);
  });

  return (
    <h1>
      {count}
      <sup>+</sup>
    </h1>
  );
}
export default function MainAbout() {
  return (
    <section className="mainAbout">
      <div className="container2">
        <Container className="mx-auto" style={{ maxWidth: "90%" }}>
          <h2 className="topH2">Meet our Team</h2>
          <p className="subtitleHero ">
            Welcome to the heartbeat of Dojo. Our team is a diverse assembly of
            passionate individuals dedicated to driving innovation and
            delivering excellence.
          </p>
        </Container>
      </div>
      <Swiper
        className="swiper"
        modules={[Navigation, Autoplay, Pagination, Scrollbar, A11y]}
        slidesPerView="auto" // عرض 3 عناصر في كل مرة
        // breakpoints={{
        //   1200: {
        //     slidesPerView: 4,
        //   },
        //   1000: {
        //     slidesPerView: 1,
        //   },
        // }}
        spaceBetween={0}
        loop={true}
        // direction={"horizontal"}
        navigation
        autoplay={{
          delay: 4000,
          disableOnInteraction: false,
          pauseOnMouseEnter: true,
        }}
        // pagination={{ clickable: true }}
      >
        <SwiperSlide>
          <div className="pic">
            <div className="over">
              <div className="info">
                <h3>Sammy Habta</h3>
                <p className="description">CEO</p>
                <div className="socialMedia">
                  <a href="https://www.linkedin.com/in/shabta/" target="_blank">
                    <img
                      src="https://dojoitsolutions.com/wp-content/uploads/2024/10/linkedin.svg"
                      alt=""
                    />
                  </a>
                  <a href="https://x.com/sammyh71" target="_blank">
                    <img
                      src="https://dojoitsolutions.com/wp-content/uploads/2024/10/x.svg"
                      alt=""
                    />
                  </a>
                </div>
              </div>
            </div>
            <img
              className="team"
              src="https://dojoitsolutions.com/wp-content/uploads/2024/10/Rectangle-51.svg"
              alt=""
            />
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="pic">
            <div className="over">
              <div className="info">
                <h3>Shah Arfeen</h3>
                <p className="description">CFO</p>
                <div className="socialMedia">
                  <a
                    href="https://www.linkedin.com/in/shaharfeen/"
                    target="_blank"
                  >
                    <img
                      src="https://dojoitsolutions.com/wp-content/uploads/2024/10/linkedin.svg"
                      alt=""
                    />
                  </a>
                  <a href="https://x.com/?mx=2" target="_blank">
                    <img
                      src="https://dojoitsolutions.com/wp-content/uploads/2024/10/x.svg"
                      alt=""
                    />
                  </a>
                </div>
              </div>
            </div>
            <img
              className="team"
              src="https://dojoitsolutions.com/wp-content/uploads/2024/10/Rectangle-52.svg"
              alt=""
            />
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="pic">
            <div className="over">
              <div className="info">
                <h3>Ayaz Hussain</h3>
                <p className="description">Technical Lead</p>
                <div className="socialMedia">
                  <a
                    href="https://www.linkedin.com/in/ayazhussain123/"
                    target="_blank"
                  >
                    <img
                      src="https://dojoitsolutions.com/wp-content/uploads/2024/10/linkedin.svg"
                      alt=""
                    />
                  </a>
                  <a href="https://x.com/syedayaz321" target="_blank">
                    <img
                      src="https://dojoitsolutions.com/wp-content/uploads/2024/10/x.svg"
                      alt=""
                    />
                  </a>
                </div>
              </div>
            </div>
            <img
              className="team"
              src="https://dojoitsolutions.com/wp-content/uploads/2024/10/Rectangle-53.svg"
              alt=""
            />
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="pic">
            <div className="over">
              <div className="info">
                <h3>Shahmeer Khan</h3>
                <p className="description">Business Consultant</p>
                <div className="socialMedia">
                  <a
                    href="https://www.linkedin.com/in/iamshahmeer/"
                    target="_blank"
                  ></a>
                  <img
                    src="https://dojoitsolutions.com/wp-content/uploads/2024/10/linkedin.svg"
                    alt=""
                  />
                  <a href="https://x.com/Shahmee63829241" target="_blank"></a>
                  <img
                    src="https://dojoitsolutions.com/wp-content/uploads/2024/10/x.svg"
                    alt=""
                  />
                </div>
              </div>
            </div>
            <img
              className="team"
              src="https://dojoitsolutions.com/wp-content/uploads/2024/10/Rectangle-54.svg"
              alt=""
            />
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="pic">
            <div className="over">
              <div className="info">
                <h3>Ramez Douedari</h3>
                <p className="description">CCO</p>
                <div className="socialMedia">
                  <a href="" target="_blank">
                    <img
                      src="https://dojoitsolutions.com/wp-content/uploads/2024/10/linkedin.svg"
                      alt=""
                    />
                  </a>
                  <a href="" target="_blank">
                    <img
                      src="https://dojoitsolutions.com/wp-content/uploads/2024/10/x.svg"
                      alt=""
                    />
                  </a>
                </div>
              </div>
            </div>
            <img
              className="team"
              src="https://dojoitsolutions.com/wp-content/uploads/2024/10/RamezAvatar.jpg"
              alt=""
            />
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="pic">
            <div className="over">
              <div className="info">
                <h3>Felipe Polo-Wood</h3>
                <p className="description">CTO</p>
                <div className="socialMedia">
                  <a href="" target="_blank">
                    <img
                      src="https://dojoitsolutions.com/wp-content/uploads/2024/10/linkedin.svg"
                      alt=""
                    />
                  </a>
                  <a href="" target="_blank">
                    <img
                      src="https://dojoitsolutions.com/wp-content/uploads/2024/10/x.svg"
                      alt=""
                    />
                  </a>
                </div>
              </div>
            </div>
            <img
              className="team"
              src="https://dojoitsolutions.com/wp-content/uploads/2024/10/IMG_0005_1_pp2_pp_mono-scaled.jpeg"
              alt=""
            />
          </div>
        </SwiperSlide>
      </Swiper>
      <div className="container2">
        <Container className="mx-auto" style={{ maxWidth: "90%" }}>
          <div className="bar">
            <div className="d-flex start">
              <h1>
                <Counter start={0} end={20} speed={40} />
              </h1>
              <p>clients served</p>
            </div>
            <img
              src="https://dojoitsolutions.com/wp-content/uploads/2024/09/Line-1.svg"
              alt=""
            />
            <div className="d-flex">
              <h1>
                <Counter start={0} end={100} speed={10} />
              </h1>
              <p>projects completed</p>
            </div>
            <img
              className="line2"
              src="https://dojoitsolutions.com/wp-content/uploads/2024/09/Line-1.svg"
              alt=""
            />
            <div className="d-flex end">
              <h1>
                <Counter start={0} end={3} speed={80} />
              </h1>
              <p>products created</p>
            </div>
          </div>
        </Container>
      </div>
    </section>
  );
}
