import Container from "react-bootstrap/Container";
import { useState, useEffect } from "react";
import "./sassCards.css";

export default function SassCards() {
  const [isHovering1, setIsHovering1] = useState(false);
  const [isHovering2, setIsHovering2] = useState(false);
  const [isHovering3, setIsHovering3] = useState(false);
  const [isHovering4, setIsHovering4] = useState(false);
  const [isHovering5, setIsHovering5] = useState(false);
  const [isHovering6, setIsHovering6] = useState(false);
  const [isHovering7, setIsHovering7] = useState(false);
  const [isHovering8, setIsHovering8] = useState(false);
  const [isHovering9, setIsHovering9] = useState(false);
  const [isHovering10, setIsHovering10] = useState(false);
  const [isHovering11, setIsHovering11] = useState(false);

  useEffect(() => {
    // Preload hover images
    const hoverImages = [
      "https://dojoitsolutions.com/wp-content/uploads/2024/09/saasCard1-icon2.svg",
      "https://dojoitsolutions.com/wp-content/uploads/2024/09/saasCard2-icon2.svg",
      "https://dojoitsolutions.com/wp-content/uploads/2024/09/saasCard3-icon2.svg",
      "https://dojoitsolutions.com/wp-content/uploads/2024/09/saasCard4-icon2.svg",
      "https://dojoitsolutions.com/wp-content/uploads/2024/09/saasCard5-icon2.svg",
      "https://dojoitsolutions.com/wp-content/uploads/2024/09/saasCard6-icon2.svg",
      "https://dojoitsolutions.com/wp-content/uploads/2024/09/saasCard7-icon2.svg",
      "https://dojoitsolutions.com/wp-content/uploads/2024/09/saasCard8-icon2.svg",
      "https://dojoitsolutions.com/wp-content/uploads/2024/09/saasCard9-icon2.svg",
      "https://dojoitsolutions.com/wp-content/uploads/2024/09/saasCard10-icon2.svg",
      "https://dojoitsolutions.com/wp-content/uploads/2024/09/saasCard11-icon2.svg",
    ];

    hoverImages.forEach((src) => {
      const img = new Image();
      img.src = src;
    });
  }, []);

  return (
    <section className="maniCardsSaas">
      <div className="container2">
        <Container
          className="mx-auto containerCards"
          style={{ maxWidth: "90%" }}
        >
          <div className="flex cradPadding">
            <div
              onMouseEnter={() => setIsHovering1(true)}
              onMouseLeave={() => setIsHovering1(false)}
              className="cards"
            >
              {!isHovering1 && (
                <div className="topIcon">
                  <img
                    src="https://dojoitsolutions.com/wp-content/uploads/2024/09/saasCard1-icon1.svg"
                    alt=""
                  />
                </div>
              )}
              {isHovering1 && (
                <div className="topIcon">
                  <img
                    src="https://dojoitsolutions.com/wp-content/uploads/2024/09/saasCard1-icon2.svg"
                    alt=""
                  />
                </div>
              )}
              <h2>SaaS Consulting</h2>
              <p className="p-1">
                We help you identify the best development lifecycle,
                methodology, technology, and an efficient cloud hosting platform
                for your SaaS-based application development process.
              </p>
              <p className="p-2">Dojo Solutions</p>
              <img
                className="tri01"
                src="https://dojoitsolutions.com/wp-content/uploads/2024/09/Vector0.svg"
                alt=""
              />
              <img
                className="log"
                src="https://dojoitsolutions.com/wp-content/uploads/2024/09/Vector1.svg"
                alt=""
              />
            </div>
            <div
              onMouseEnter={() => setIsHovering2(true)}
              onMouseLeave={() => setIsHovering2(false)}
              className="cards"
            >
              {!isHovering2 && (
                <div className="topIcon">
                  <img
                    src="https://dojoitsolutions.com/wp-content/uploads/2024/09/saasCard2-icon1.svg"
                    alt=""
                  />
                </div>
              )}
              {isHovering2 && (
                <div className="topIcon">
                  <img
                    src="https://dojoitsolutions.com/wp-content/uploads/2024/09/saasCard2-icon2.svg"
                    alt=""
                  />
                </div>
              )}
              <h2>SaaS App Development</h2>
              <p className="p-1">
                Our team of SaaS app builders has helped over 20 companies
                develop applications that are ready to handle multiple real-time
                requests and can be scaled to grow as the business grows.
              </p>
              <p className="p-2">Dojo Solutions</p>
              <img
                className="card2-1"
                src="https://dojoitsolutions.com/wp-content/uploads/2024/09/card2-1.svg"
                alt=""
              />
              <img
                className="card2-2"
                src="https://dojoitsolutions.com/wp-content/uploads/2024/09/card2-2.svg"
                alt=""
              />
              <img
                className="card2-3"
                src="https://dojoitsolutions.com/wp-content/uploads/2024/09/card2-3.svg"
                alt=""
              />
            </div>
            <div
              onMouseEnter={() => setIsHovering3(true)}
              onMouseLeave={() => setIsHovering3(false)}
              className="cards"
            >
              {!isHovering3 && (
                <div className="topIcon">
                  <img
                    src="https://dojoitsolutions.com/wp-content/uploads/2024/09/saasCard3-icon1.svg"
                    alt=""
                  />
                </div>
              )}
              {isHovering3 && (
                <div className="topIcon">
                  <img
                    src="https://dojoitsolutions.com/wp-content/uploads/2024/09/saasCard3-icon2.svg"
                    alt=""
                  />
                </div>
              )}
              <h2>SaaS App Optimization</h2>
              <p className="p-1">
                Our SaaS app optimization experts work with you throughout your
                service offering to help derive maximum returns.
              </p>
              <p className="p-2">Dojo Solutions</p>
              <img
                className="card2-1"
                src="https://dojoitsolutions.com/wp-content/uploads/2024/09/card2-1.svg"
                alt=""
              />
              <img
                className="card2-2"
                src="https://dojoitsolutions.com/wp-content/uploads/2024/09/card2-2.svg"
                alt=""
              />
              <img
                className="card2-3"
                src="https://dojoitsolutions.com/wp-content/uploads/2024/09/card2-3.svg"
                alt=""
              />
            </div>
            <div
              onMouseEnter={() => setIsHovering4(true)}
              onMouseLeave={() => setIsHovering4(false)}
              className="cards"
            >
              {!isHovering4 && (
                <div className="topIcon">
                  <img
                    src="https://dojoitsolutions.com/wp-content/uploads/2024/09/saasCard4-icon1.svg"
                    alt=""
                  />
                </div>
              )}
              {isHovering4 && (
                <div className="topIcon">
                  <img
                    src="https://dojoitsolutions.com/wp-content/uploads/2024/09/saasCard4-icon2.svg"
                    alt=""
                  />
                </div>
              )}
              <h2>SaaS App Design</h2>
              <p className="p-1">
                We focus our design strategy on workflows and user movement
                mapping - a strategy that helps us design the apps which will be
                ready for use on a mass scale.
              </p>
              <p className="p-2">Dojo Solutions</p>
              <img
                className="card4-1"
                src="https://dojoitsolutions.com/wp-content/uploads/2024/09/card4-1.svg"
                alt=""
              />
              <img
                className="card4-2"
                src="https://dojoitsolutions.com/wp-content/uploads/2024/09/card4-2.svg"
                alt=""
              />
              <img
                className="card4-3"
                src="https://dojoitsolutions.com/wp-content/uploads/2024/09/card4-3.svg"
                alt=""
              />
            </div>
            <div
              onMouseEnter={() => setIsHovering5(true)}
              onMouseLeave={() => setIsHovering5(false)}
              className="cards"
            >
              {!isHovering5 && (
                <div className="topIcon">
                  <img
                    src="https://dojoitsolutions.com/wp-content/uploads/2024/09/saasCard5-icon1.svg"
                    alt=""
                  />
                </div>
              )}
              {isHovering5 && (
                <div className="topIcon">
                  <img
                    src="https://dojoitsolutions.com/wp-content/uploads/2024/09/saasCard5-icon2.svg"
                    alt=""
                  />
                </div>
              )}
              <h2>Multi-Tenant Architecture Upgrade</h2>
              <p className="p-1">
                We follow an upgrade and enhancement process for your existing
                SaaS application to help you make it multi-tenant. Throughout
                the process, we help you derive maximum monetization and lower
                your long-term maintenance costs.
              </p>
              <p className="p-2">Dojo Solutions</p>
              <img
                className="card5-1"
                src="https://dojoitsolutions.com/wp-content/uploads/2024/09/card5-1.svg"
                alt=""
              />
              <img
                className="card5-2"
                src="https://dojoitsolutions.com/wp-content/uploads/2024/09/card5-2.svg"
                alt=""
              />
            </div>
            <div
              onMouseEnter={() => setIsHovering6(true)}
              onMouseLeave={() => setIsHovering6(false)}
              className="cards"
            >
              {!isHovering6 && (
                <div className="topIcon">
                  <img
                    src="https://dojoitsolutions.com/wp-content/uploads/2024/09/saasCard6-icon1.svg"
                    alt=""
                  />
                </div>
              )}
              {isHovering6 && (
                <div className="topIcon">
                  <img
                    src="https://dojoitsolutions.com/wp-content/uploads/2024/09/saasCard6-icon2.svg"
                    alt=""
                  />
                </div>
              )}
              <h2>Project Description</h2>
              <p className="p-1">
                After signing an NDA promising complete confidentiality, our
                project manager composes a document with the technical
                requirements of your SaaS application.
              </p>
              <p className="p-2">Dojo Solutions</p>
              <img
                className="tri01"
                src="https://dojoitsolutions.com/wp-content/uploads/2024/09/Vector0.svg"
                alt=""
              />
              <img
                className="log"
                src="https://dojoitsolutions.com/wp-content/uploads/2024/09/Vector1.svg"
                alt=""
              />
            </div>
            <div
              onMouseEnter={() => setIsHovering7(true)}
              onMouseLeave={() => setIsHovering7(false)}
              className="cards"
            >
              {!isHovering7 && (
                <div className="topIcon">
                  <img
                    src="https://dojoitsolutions.com/wp-content/uploads/2024/09/saasCard7-icon1.svg"
                    alt=""
                  />
                </div>
              )}
              {isHovering7 && (
                <div className="topIcon">
                  <img
                    src="https://dojoitsolutions.com/wp-content/uploads/2024/09/saasCard7-icon2.svg"
                    alt=""
                  />
                </div>
              )}
              <h2>Code Design</h2>
              <p className="p-1">
                In the third part of the process, our SaaS application
                developers code your design into HTML while adding quality notes
                so that any developer can navigate through the backend while
                developing the functions in JavaScript.
              </p>
              <p className="p-2">Dojo Solutions</p>
              <img
                className="card2-1"
                src="https://dojoitsolutions.com/wp-content/uploads/2024/09/card2-1.svg"
                alt=""
              />
              <img
                className="card2-2"
                src="https://dojoitsolutions.com/wp-content/uploads/2024/09/card2-2.svg"
                alt=""
              />
              <img
                className="card2-3"
                src="https://dojoitsolutions.com/wp-content/uploads/2024/09/card2-3.svg"
                alt=""
              />
            </div>
            <div
              onMouseEnter={() => setIsHovering8(true)}
              onMouseLeave={() => setIsHovering8(false)}
              className="cards"
            >
              {!isHovering8 && (
                <div className="topIcon">
                  <img
                    src="https://dojoitsolutions.com/wp-content/uploads/2024/09/saasCard8-icon1.svg"
                    alt=""
                  />
                </div>
              )}
              {isHovering8 && (
                <div className="topIcon">
                  <img
                    src="https://dojoitsolutions.com/wp-content/uploads/2024/09/saasCard8-icon2.svg"
                    alt=""
                  />
                </div>
              )}
              <h2>Process Design</h2>
              <p className="p-1">
                In the second part of the process, we design your application
                based on user journey mapping and deliver the mockup in two
                versions, Mobile and Desktop, for confirmation.
              </p>
              <p className="p-2">Dojo Solutions</p>
              <img
                className="card2-1"
                src="https://dojoitsolutions.com/wp-content/uploads/2024/09/card2-1.svg"
                alt=""
              />
              <img
                className="card2-2"
                src="https://dojoitsolutions.com/wp-content/uploads/2024/09/card2-2.svg"
                alt=""
              />
              <img
                className="card2-3"
                src="https://dojoitsolutions.com/wp-content/uploads/2024/09/card2-3.svg"
                alt=""
              />
            </div>
            <div
              onMouseEnter={() => setIsHovering9(true)}
              onMouseLeave={() => setIsHovering9(false)}
              className="cards"
            >
              {!isHovering9 && (
                <div className="topIcon">
                  <img
                    src="https://dojoitsolutions.com/wp-content/uploads/2024/09/saasCard9-icon1.svg"
                    alt=""
                  />
                </div>
              )}
              {isHovering9 && (
                <div className="topIcon">
                  <img
                    src="https://dojoitsolutions.com/wp-content/uploads/2024/09/saasCard9-icon2.svg"
                    alt=""
                  />
                </div>
              )}
              <h2>Backend Comes to Life</h2>
              <p className="p-1">
                At this stage, the database gets integrated, and our team of
                skilled backend SaaS application developers develops the logic
                of work.
              </p>
              <p className="p-2">Dojo Solutions</p>
              <img
                className="card4-1"
                src="https://dojoitsolutions.com/wp-content/uploads/2024/09/card4-1.svg"
                alt=""
              />
              <img
                className="card4-2"
                src="https://dojoitsolutions.com/wp-content/uploads/2024/09/card4-2.svg"
                alt=""
              />
              <img
                className="card4-3"
                src="https://dojoitsolutions.com/wp-content/uploads/2024/09/card4-3.svg"
                alt=""
              />
            </div>
            <div
              onMouseEnter={() => setIsHovering10(true)}
              onMouseLeave={() => setIsHovering10(false)}
              className="cards"
            >
              {!isHovering10 && (
                <div className="topIcon">
                  <img
                    src="https://dojoitsolutions.com/wp-content/uploads/2024/09/saasCard10-icon1.svg"
                    alt=""
                  />
                </div>
              )}
              {isHovering10 && (
                <div className="topIcon">
                  <img
                    src="https://dojoitsolutions.com/wp-content/uploads/2024/09/saasCard10-icon2.svg"
                    alt=""
                  />
                </div>
              )}
              <h2>SaaS Maintenance</h2>
              <p className="p-1">
                We are a SaaS application development company that offers a
                6-month warranty with a Service Level Agreement on developed
                SaaS solutions. We also provide the provision to add new
                functionalities to the software when required.
              </p>
              <p className="p-2">Dojo Solutions</p>
              <img
                className="card5-1"
                src="https://dojoitsolutions.com/wp-content/uploads/2024/09/card5-1.svg"
                alt=""
              />
              <img
                className="card5-2"
                src="https://dojoitsolutions.com/wp-content/uploads/2024/09/card5-2.svg"
                alt=""
              />
            </div>
            <div
              onMouseEnter={() => setIsHovering11(true)}
              onMouseLeave={() => setIsHovering11(false)}
              className="cards"
            >
              {!isHovering11 && (
                <div className="topIcon">
                  <img
                    src="https://dojoitsolutions.com/wp-content/uploads/2024/09/saasCard11-icon1.svg"
                    alt=""
                  />
                </div>
              )}
              {isHovering11 && (
                <div className="topIcon">
                  <img
                    src="https://dojoitsolutions.com/wp-content/uploads/2024/09/saasCard11-icon2.svg"
                    alt=""
                  />
                </div>
              )}
              <h2>SaaS Application Testing</h2>
              <p className="p-1">
                Our SaaS-based application development process ends with our
                team of QA specialists studying your app’s performance under a
                number of different scenarios. This process ensures that you are
                able to use your software without any issues.
              </p>
              <p className="p-2">Dojo Solutions</p>
              <img
                className="card5-1"
                src="https://dojoitsolutions.com/wp-content/uploads/2024/09/card5-1.svg"
                alt=""
              />
              <img
                className="card5-2"
                src="https://dojoitsolutions.com/wp-content/uploads/2024/09/card5-2.svg"
                alt=""
              />
            </div>
          </div>
        </Container>
      </div>
    </section>
  );
}