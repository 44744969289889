import { useState } from "react";
import { HashLink } from "react-router-hash-link";
import "./nav.css";
import { Link } from "react-router-dom";

import Container from "react-bootstrap/Container";
export default function Nav() {
  const [showModal, setShowModal] = useState(false);
  return (
    <div className="hidden">
      <div className="new">
        <div className="mesh">
          <img
            className="star"
            src="http://dojoitsolutions.com/wp-content/uploads/2024/08/Star-1-1.svg"
          ></img>
          <img
            className="rectangle"
            src="http://dojoitsolutions.com/wp-content/uploads/2024/08/Rectangle-36.svg"
          ></img>
          <img
            className="polygon1"
            src="http://dojoitsolutions.com/wp-content/uploads/2024/08/Polygon-1-1.svg"
          ></img>
          <img
            className="polygon2"
            src="http://dojoitsolutions.com/wp-content/uploads/2024/08/Polygon-2-1.svg"
          ></img>
          <img
            className="ellipse5"
            src="http://dojoitsolutions.com/wp-content/uploads/2024/08/Ellipse-5-2.svg"
          ></img>
          <img
            className="ellipse6"
            src="http://dojoitsolutions.com/wp-content/uploads/2024/08/Ellipse-6-1.svg"
          ></img>
        </div>
      </div>
      <Container className="mx-auto" style={{ maxWidth: "90%" }}>
        <div className="nav">
          <Link to="/home">
            <img
              className="logo"
              src="https://dojoitsolutions.com/wp-content/uploads/2024/09/logoo.svg"
              alt=""
            />
          </Link>
          <img
            className="menu"
            onClick={() => {
              setShowModal(true);
            }}
            src="http://dojoitsolutions.com/wp-content/uploads/2024/08/bars-1.svg"
            alt=""
          />
          <nav>
            <ul>
              <li>
                <HashLink to={"/home"}>Home</HashLink>
              </li>
              <li>
                <HashLink to={"/about"}>About</HashLink>
              </li>
              <li className="dropdown">
                <HashLink to="/servicesPage">
                  Services <span>&#x25BC;</span>
                </HashLink>
                <ul className="drop">
                  <li>
                    <HashLink to="/wepApp">
                      Web Application Development
                    </HashLink>
                  </li>
                  <li>
                    <HashLink to="/mobileApp">
                      Mobile Application Development
                    </HashLink>
                  </li>
                  <li>
                    <HashLink to="/sass">SaaS Application Development</HashLink>
                  </li>
                  <li>
                    <HashLink to="/uiux">UI/UX Design</HashLink>
                  </li>
                  <li>
                    <HashLink to="/software">
                      Software Application Maintenance
                    </HashLink>
                  </li>
                  <li>
                    <HashLink to="/testing">Software Testing & QA</HashLink>
                  </li>
                </ul>
              </li>
              <li>
                <HashLink to={"/portfolio"}>Our Portfolio</HashLink>
              </li>

              <li>
                <Link to={"/contact"}>
                  <button>Let’s Talk</button>
                </Link>
              </li>
            </ul>
          </nav>
          {showModal && (
            <div className="fixed">
              <ul className="mod">
                {/* <button onClick={setShowModal(false)}>x</button> */}
                <li>
                  <button
                    onClick={() => {
                      setShowModal(false);
                    }}
                  >
                    x
                  </button>
                </li>
                <li>
                  <HashLink
                    onClick={() => {
                      setShowModal(false);
                    }}
                    to={"/home"}
                  >
                    Home
                  </HashLink>
                </li>
                <li>
                  <HashLink
                    onClick={() => {
                      setShowModal(false);
                    }}
                    to={"/about"}
                  >
                    About
                  </HashLink>
                </li>
                <li>
                  <HashLink
                    onClick={() => {
                      setShowModal(false);
                    }}
                    to="/servicesPage"
                  >
                    Services
                  </HashLink>
                </li>
                <li>
                  <HashLink
                    onClick={() => {
                      setShowModal(false);
                    }}
                    to={"/portfolio"}
                  >
                    Our Portfolio
                  </HashLink>
                </li>

                <li className="bttn">
                  <HashLink
                    onClick={() => {
                      setShowModal(false);
                    }}
                    to={"/contact"}
                  >
                    <button>Let’s Talk</button>
                  </HashLink>
                </li>
              </ul>
            </div>
          )}
        </div>
      </Container>
    </div>
  );
}
