import { useState, useEffect, useRef } from "react";
import Container from "react-bootstrap/Container";
import "./mobileRoadmap.css";
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";

export default function MobileRoadmap() {
  const sectionRef = useRef(null);
  const [image, setImage] = useState("image1.jpg");
  const [imageClass, setImageClass] = useState("normal");
  const [ref, inView] = useInView({
    triggerOnce: false,
    threshold: 0.1,
  });

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 700) {
        setImageClass("mini");
        setImage(
          "https://dojoitsolutions.com/wp-content/uploads/2024/09/roadminimob.svg"
        );
      } else {
        setImageClass("normal");

        setImage(
          "https://dojoitsolutions.com/wp-content/uploads/2024/09/roadmobile.svg"
        );
      }
    };

    window.addEventListener("resize", handleResize);
    handleResize(); // تشغيل الدالة عند تحميل الصفحة لأول مرة

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <section className="mobileRoadmap">
      <div className="container2">
        <Container className="mx-auto" style={{ maxWidth: "90%" }}>
          <h2 className="topH2">
            Mobile App Development <span>Cycle</span>
          </h2>
          <p className="topP">
            Our Solution Development Cycle helps turn your great ideas into
            profitable business solutions.
          </p>
        </Container>
        <div className="mobRoadmap" ref={ref}>
          <img
            className={imageClass === "normal" ? "road" : "miniroad"}
            src={image}
            alt=""
          />
          {[1, 2, 3, 4].map((step) => (
            <motion.div
              key={step}
              initial={{ opacity: 0, x: -200 }}
              animate={{ opacity: inView ? 1 : 0, x: inView ? 0 : -200 }}
              transition={{ duration: 0.5, delay: step * 0.2 }}
            >
              <img
                className={`step${step}Mob`}
                src={`https://dojoitsolutions.com/wp-content/uploads/2024/09/step${step}mob${
                  step === 2 || step === 3 ? "ile" : ""
                }.svg`}
                alt=""
              />
              <img
                className={`step${step}MobTitle${step === 4 ? "Mob" : ""}`}
                src={`https://dojoitsolutions.com/wp-content/uploads/2024/09/step${step}mobiletitle.svg`}
                alt=""
              />
            </motion.div>
          ))}
        </div>
      </div>
    </section>
  );
}
