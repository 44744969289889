// import React, { useEffect, useState } from "react";
import React, { useRef } from "react";
import Container from "react-bootstrap/Container";
import "./section5.css";
import { motion, useInView } from "framer-motion";
export default function Section5() {
  const sectionRef = useRef(null);
  const isInView = useInView(sectionRef, { once: false, amount: 0.3 });
  // const [section1MainTitle5, setSectionMainTitle5] = useState(false);
  // const [section1SubTitle5, setSectionSubTitle5] = useState(false);
  // const [lgView5, setLgView5] = useState(false);

  // useEffect(() => {
  //   window.addEventListener("scroll", () => {
  //     if (window.scrollY >= 0) {
  //       console.log(window.scrollY);
  //     }
  //     if (window.scrollY >= 4270 && window.innerWidth >= 2500) {
  //       setLgView5(true);
  //     }
  //     if (window.scrollY >= 3014 && window.innerWidth < 2500) {
  //       setSectionMainTitle5(true);
  //     }
  //     if (window.scrollY >= 3214 && window.innerWidth < 2500) {
  //       setSectionSubTitle5(true);
  //     }
  //   });
  // }, []);
  // useEffect(() => {
  //   window.addEventListener("scroll", () => {
  //     if (window.scrollY >= 0) {
  //       console.log(window.scrollY);
  //     }
  //     if (window.scrollY >= 4390 && window.innerWidth >= 2500) {
  //       setLgView5(true);
  //     }
  //     if (window.scrollY >= 3050 && window.innerWidth < 2500) {
  //       setSectionMainTitle5(true);
  //     }
  //     if (window.scrollY >= 3350 && window.innerWidth < 2500) {
  //       setSectionSubTitle5(true);
  //     }
  //   });
  // }, []);

  return (
    <section className="section5" ref={sectionRef} id="takle">
      <div className="overlay"></div>
      <div className="container2">
        <Container className="mx-auto" style={{ maxWidth: "90%" }}>
          {/* <img
            className="blue"
            src="https://dojoitsolutions.com/wp-content/uploads/2024/09/pink.svg"
            alt=""
          /> */}
          <motion.div
            className="motion"
            initial={{ opacity: 0, x: -200 }}
            animate={{ opacity: isInView ? 1 : 0, x: isInView ? 0 : -200 }}
            transition={{ duration: 0.5, delay: 0.4 }}
          >
            <a href="https://takle4creators.com/" target="_blank">
              <img
                className="mobilelink5"
                src="https://dojoitsolutions.com/wp-content/uploads/2024/10/tackle11.png"
                alt=""
              />
            </a>
          </motion.div>
          <motion.div
            className="mainTitle5"
            initial={{ opacity: 0, x: -200 }}
            animate={{ opacity: isInView ? 1 : 0, x: isInView ? 0 : -200 }}
            transition={{ duration: 0.1 }}
          >
            <h2>Takle</h2>
            <p>Join Creators worldwide Ultimate Creator toolkit.</p>
          </motion.div>

          <motion.div
            className="subTitle5"
            initial={{ opacity: 0, x: 200 }}
            animate={{ opacity: isInView ? 1 : 0, x: isInView ? 0 : 200 }}
            transition={{ duration: 0.1, delay: 0.3 }}
          >
            <p>
              Why settle for less? With Takle, you’ll never have to compromise
              on fair compensation for your content. Join us now.
            </p>
            <a href="https://takle4creators.com/" target="_blank">
              VISIT SITE{" "}
            </a>
          </motion.div>

          <div className="dividerPortfolio2">
            <img
              className="dividerPortfolio"
              src="https://dojoitsolutions.com/wp-content/uploads/2024/10/newpink.svg"
              alt=""
            />
          </div>
        </Container>
      </div>
    </section>
  );
}
