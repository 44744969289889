// import React, { useEffect, useState } from "react";
import React, { useRef } from "react";
import { motion, useInView } from "framer-motion";

import Container from "react-bootstrap/Container";
import "./section6.css";
export default function Section6() {
  const sectionRef = useRef(null);
  const isInView = useInView(sectionRef, { once: false, amount: 0.3 });
  // const [section1MainTitle6, setSectionMainTitle6] = useState(false);
  // const [section1SubTitle6, setSectionSubTitle6] = useState(false);
  // const [lgView6, setLgView6] = useState(false);
  // useEffect(() => {
  //   window.addEventListener("scroll", () => {
  //     if (window.scrollY >= 0) {
  //       console.log(window.scrollY);
  //     }
  //     if (window.scrollY >= 6920 && window.innerWidth >= 2500) {
  //       setLgView6(true);
  //     }
  //     if (window.scrollY >= 4550 && window.innerWidth < 2500) {
  //       setSectionMainTitle6(true);
  //     }
  //     if (window.scrollY >= 4850 && window.innerWidth < 2500) {
  //       setSectionSubTitle6(true);
  //     }
  //   });
  // }, []);
  // useEffect(() => {
  //   window.addEventListener("scroll", () => {
  //     if (window.scrollY >= 0) {
  //       console.log(window.scrollY);
  //     }
  //     if (window.scrollY >= 5650 && window.innerWidth >= 2500) {
  //       setLgView6(true);
  //     }
  //     if (window.scrollY >= 3830 && window.innerWidth < 2500) {
  //       setSectionMainTitle6(true);
  //     }
  //     if (window.scrollY >= 4030 && window.innerWidth < 2500) {
  //       setSectionSubTitle6(true);
  //     }
  //   });
  // }, []);
  return (
    <section className="section6" ref={sectionRef} id="baraka">
      <div className="overlay"></div>
      <div className="container2">
        <Container className="mx-auto" style={{ maxWidth: "90%" }}>
          <motion.div
            className="motion"
            initial={{ opacity: 0, x: 200 }}
            animate={{ opacity: isInView ? 1 : 0, x: isInView ? 0 : 200 }}
            transition={{ duration: 0.5, delay: 0.4 }}
          >
            <a href="https://albarakamarketnc.com/" target="_blank">
              <img
                className="mobilelink6"
                src="https://dojoitsolutions.com/wp-content/uploads/2024/10/albaraka11.png"
                alt=""
              />
            </a>
          </motion.div>

          <motion.div
            className="mainTitle6"
            initial={{ opacity: 0, x: 200 }}
            animate={{ opacity: isInView ? 1 : 0, x: isInView ? 0 : 200 }}
            transition={{ duration: 0.1 }}
          >
            <h2>Al Baraka</h2>
            <p>
              Al-Baraka Market serves high-end Mediterranean grocery items in
              the metropolitan city, Raleigh.
            </p>
          </motion.div>
          <motion.div
            className="subTitle6"
            initial={{ opacity: 0, x: -200 }}
            animate={{ opacity: isInView ? 1 : 0, x: isInView ? 0 : -200 }}
            transition={{ duration: 0.1, delay: 0.3 }}
          >
            <p>
              As the community’s backbone for decades, Al-Baraka Market ensures
              the pure organic and exquisite selection of Mediterranean
              groceries.
            </p>
            <a href="https://albarakamarketnc.com/" target="_blank">
              VISIT SITE
            </a>
          </motion.div>
          <div className="dividerPortfolio2">
            <img
              className="dividerPortfolio"
              src="https://dojoitsolutions.com/wp-content/uploads/2024/10/newyellwo.svg"
              alt=""
            />
          </div>
        </Container>
      </div>
    </section>
  );
}
