import Container from "react-bootstrap/Container";
import { useState, useEffect } from "react";
import "./softwareCards.css";
export default function SoftwareCards() {
  const [isHovering1, setIsHovering1] = useState(false);
  const [isHovering2, setIsHovering2] = useState(false);
  const [isHovering3, setIsHovering3] = useState(false);
  const [isHovering5, setIsHovering5] = useState(false);

  // إضافة مصفوفة تحتوي على روابط الصور التي نريد تحميلها مسبقًا
  const preloadImages = [
    "https://dojoitsolutions.com/wp-content/uploads/2024/09/softwareCard1-icon2.svg",
    "https://dojoitsolutions.com/wp-content/uploads/2024/09/softwareCard2-icon2.svg",
    "https://dojoitsolutions.com/wp-content/uploads/2024/09/softwareCard3-icon2.svg",
    "https://dojoitsolutions.com/wp-content/uploads/2024/09/softwareCard4-icon2.svg"
  ];

  useEffect(() => {
    // تحميل الصور مسبقًا عند تحميل المكون
    preloadImages.forEach((src) => {
      const img = new Image();
      img.src = src;
    });
  }, []);

  return (
    <section className="softwareCard">
      {/* إضافة عنصر div مخفي لتحميل الصور مسبقًا */}
      <div style={{ display: 'none' }}>
        {preloadImages.map((src, index) => (
          <img key={index} src={src} alt="Preloaded" />
        ))}
      </div>
      
      <div className="container2">
        <Container
          className="mx-auto containerCards"
          style={{ maxWidth: "90%" }}
        >
          <h2 className="topH2">
            Why <span>Customers </span>
            Choose Us ?
          </h2>
          <p className="topP">
            We aim to solve flaws and malfunctions in the product and improve
            its performance with our Software Maintenance services. As a result,
            to guarantee that your software or application continues to work
            smoothly, it must be refreshed regularly.
          </p>
          <div className="flex cradPadding">
            <div
              onMouseEnter={() => setIsHovering1(true)}
              onMouseLeave={() => setIsHovering1(false)}
              className="cards"
            >
              {!isHovering1 && (
                <div className="topIcon">
                  <img
                    src="https://dojoitsolutions.com/wp-content/uploads/2024/09/softwareCard1-icon1.svg"
                    alt=""
                  />
                </div>
              )}
              {isHovering1 && (
                <div className="topIcon">
                  <img
                    src="https://dojoitsolutions.com/wp-content/uploads/2024/09/softwareCard1-icon2.svg"
                    alt=""
                  />
                </div>
              )}
              <h2>Performance Improvement</h2>
              <p className="p-1">
                Software maintenance and support services improve the overall
                performance and functionality of the application or software.
              </p>
              <p className="p-2">Dojo Solutions</p>
              <img
                className="tri01"
                src="https://dojoitsolutions.com/wp-content/uploads/2024/09/Vector0.svg"
                alt=""
              />
              <img
                className="log"
                src="https://dojoitsolutions.com/wp-content/uploads/2024/09/Vector1.svg"
                alt=""
              />
            </div>
            <div
              onMouseEnter={() => setIsHovering2(true)}
              onMouseLeave={() => setIsHovering2(false)}
              className="cards"
            >
              {!isHovering2 && (
                <div className="topIcon">
                  <img
                    src="https://dojoitsolutions.com/wp-content/uploads/2024/09/softwareCard2-icon1.svg"
                    alt=""
                  />
                </div>
              )}
              {isHovering2 && (
                <div className="topIcon">
                  <img
                    src="https://dojoitsolutions.com/wp-content/uploads/2024/09/softwareCard2-icon2.svg"
                    alt=""
                  />
                </div>
              )}
              <h2>Removal of Outdated Functions</h2>
              <p className="p-1">
                Useless elements of coding are removed and replaced with new
                ones using the latest tools and technologies.
              </p>
              <p className="p-2">Dojo Solutions</p>
              <img
                className="card2-1"
                src="https://dojoitsolutions.com/wp-content/uploads/2024/09/card2-1.svg"
                alt=""
              />
              <img
                className="card2-2"
                src="https://dojoitsolutions.com/wp-content/uploads/2024/09/card2-2.svg"
                alt=""
              />
              <img
                className="card2-3"
                src="https://dojoitsolutions.com/wp-content/uploads/2024/09/card2-3.svg"
                alt=""
              />
            </div>
            <div
              onMouseEnter={() => setIsHovering3(true)}
              onMouseLeave={() => setIsHovering3(false)}
              className="cards"
            >
              {!isHovering3 && (
                <div className="topIcon">
                  <img
                    src="https://dojoitsolutions.com/wp-content/uploads/2024/09/softwareCard3-icon1.svg"
                    alt=""
                  />
                </div>
              )}
              {isHovering3 && (
                <div className="topIcon">
                  <img
                    src="https://dojoitsolutions.com/wp-content/uploads/2024/09/softwareCard3-icon2.svg"
                    alt=""
                  />
                </div>
              )}
              <h2>Software Testing</h2>
              <p className="p-1">
                Through the testing process, we clear any issues or problems
                that could arise in the future prior to deployment
              </p>
              <p className="p-2">Dojo Solutions</p>
              <img
                className="card2-1"
                src="https://dojoitsolutions.com/wp-content/uploads/2024/09/card2-1.svg"
                alt=""
              />
              <img
                className="card2-2"
                src="https://dojoitsolutions.com/wp-content/uploads/2024/09/card2-2.svg"
                alt=""
              />
              <img
                className="card2-3"
                src="https://dojoitsolutions.com/wp-content/uploads/2024/09/card2-3.svg"
                alt=""
              />
            </div>
            <div
              onMouseEnter={() => setIsHovering5(true)}
              onMouseLeave={() => setIsHovering5(false)}
              className="cards"
            >
              {!isHovering5 && (
                <div className="topIcon">
                  <img
                    src="https://dojoitsolutions.com/wp-content/uploads/2024/09/softwareCard4-icon1.svg"
                    alt=""
                  />
                </div>
              )}
              {isHovering5 && (
                <div className="topIcon">
                  <img
                    src="https://dojoitsolutions.com/wp-content/uploads/2024/09/softwareCard4-icon2.svg"
                    alt=""
                  />
                </div>
              )}
              <h2>Cost Savings</h2>
              <p className="p-1">
                Reset the costs of ongoing annual support by eliminating entire
                segments of functionality that are no longer in use.
              </p>
              <p className="p-2">Dojo Solutions</p>
              <img
                className="card5-1"
                src="https://dojoitsolutions.com/wp-content/uploads/2024/09/card5-1.svg"
                alt=""
              />
              <img
                className="card5-2"
                src="https://dojoitsolutions.com/wp-content/uploads/2024/09/card5-2.svg"
                alt=""
              />
            </div>
          </div>
        </Container>
      </div>
    </section>
  );
}
