import { useState, useEffect } from "react";
import "./softwareRoadmap.css";
import Container from "react-bootstrap/Container";
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";

export default function SoftwareRoadmap() {
  const [imageClass, setImageClass] = useState("normal");
  const [image, setImage] = useState(
    "https://dojoitsolutions.com/wp-content/uploads/2024/09/softwareRoadmap.svg"
  );

  const [ref, inView] = useInView({
    triggerOnce: false,
    threshold: 0.1,
  });

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 700) {
        setImageClass("mini");
        setImage(
          "https://dojoitsolutions.com/wp-content/uploads/2024/09/roadsoftmini.svg"
        );
      } else {
        setImageClass("normal");
        setImage(
          "https://dojoitsolutions.com/wp-content/uploads/2024/09/roadsoft.svg"
        );
      }
    };

    window.addEventListener("resize", handleResize);
    handleResize();

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <section className="softwareRoadmap">
      <div className="container2">
        <Container className="mx-auto" style={{ maxWidth: "90%" }}>
          <h2 className="topH2">
            Design process with our <span>UXD</span> practices
          </h2>
          <p className="topP">
            Our Solution Development Cycle helps turn your great ideas into
            profitable business solutions.
          </p>
          <div className="sfRoadmap" ref={ref}>
            <img
              className={imageClass === "normal" ? "roadSoft" : "miniRoadSoft"}
              src={image}
              alt=""
            />
            <motion.div
              initial={{ opacity: 0, x: -200 }}
              animate={{ opacity: inView ? 1 : 0, x: inView ? 0 : -200 }}
              transition={{ duration: 0.3, delay: 0.2 }}
            >
              <img
                className="step1Soft"
                src="https://dojoitsolutions.com/wp-content/uploads/2024/09/step1soft.svg"
                alt=""
              />
              <img
                className="step1SoftTitle"
                src="https://dojoitsolutions.com/wp-content/uploads/2024/09/step1softtitle.svg"
                alt=""
              />
            </motion.div>
            <motion.div
              initial={{ opacity: 0, x: 200 }}
              animate={{ opacity: inView ? 1 : 0, x: inView ? 0 : 200 }}
              transition={{ duration: 0.3, delay: 0.4 }}
            >
              <img
                className="step2Soft"
                src="https://dojoitsolutions.com/wp-content/uploads/2024/09/step2soft.svg"
                alt=""
              />
              <img
                className="step2SoftTitle"
                src="https://dojoitsolutions.com/wp-content/uploads/2024/09/step2softtitle.svg"
                alt=""
              />
            </motion.div>
            <motion.div
              initial={{ opacity: 0, x: -200 }}
              animate={{ opacity: inView ? 1 : 0, x: inView ? 0 : -200 }}
              transition={{ duration: 0.5, delay: 0.4 }}
            >
              <img
                className="step3Soft"
                src="https://dojoitsolutions.com/wp-content/uploads/2024/09/step3soft.svg"
                alt=""
              />
              <img
                className="step3SoftTitle"
                src="https://dojoitsolutions.com/wp-content/uploads/2024/09/step3softtitle.svg"
                alt=""
              />
            </motion.div>
            <motion.div
              initial={{ opacity: 0, x: 200 }}
              animate={{ opacity: inView ? 1 : 0, x: inView ? 0 : 200 }}
              transition={{ duration: 0.5, delay: 0.6 }}
            >
              <img
                className="step4Soft"
                src="https://dojoitsolutions.com/wp-content/uploads/2024/09/step4soft.svg"
                alt=""
              />
              <img
                className="step4SoftTitle"
                src="https://dojoitsolutions.com/wp-content/uploads/2024/10/step4softtitle.svg"
                alt=""
              />
            </motion.div>
            <motion.div
              initial={{ opacity: 0, x: -200 }}
              animate={{ opacity: inView ? 1 : 0, x: inView ? 0 : -200 }}
              transition={{ duration: 0.5, delay: 0.6 }}
            >
              <img
                className="step5Soft"
                src="https://dojoitsolutions.com/wp-content/uploads/2024/09/step5soft.svg"
                alt=""
              />
              <img
                className="step5SoftTitle"
                src="https://dojoitsolutions.com/wp-content/uploads/2024/09/step5softtitle.svg"
                alt=""
              />
            </motion.div>
          </div>
        </Container>
      </div>
    </section>
  );
}
