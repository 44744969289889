import Container from "react-bootstrap/Container";
import { useState, useEffect } from "react";

import "./cards.css";
export default function Cards() {
  const [isHovering1, setIsHovering1] = useState(false);
  const [isHovering2, setIsHovering2] = useState(false);
  const [isHovering3, setIsHovering3] = useState(false);
  const [isHovering4, setIsHovering4] = useState(false);
  const [isHovering5, setIsHovering5] = useState(false);

  useEffect(() => {
    const imagesToPreload = [
      "https://dojoitsolutions.com/wp-content/uploads/2024/09/Vectorrr.svg",
      "https://dojoitsolutions.com/wp-content/uploads/2024/09/card2-icon2.svg",
      "https://dojoitsolutions.com/wp-content/uploads/2024/09/card3-icon2.svg",
      "https://dojoitsolutions.com/wp-content/uploads/2024/09/card4-icon2.svg",
      "https://dojoitsolutions.com/wp-content/uploads/2024/09/card5-icon2.svg"
    ];

    imagesToPreload.forEach(src => {
      const link = document.createElement('link');
      link.rel = 'preload';
      link.as = 'image';
      link.href = src;
      document.head.appendChild(link);
    });
  }, []);

  return (
    <section className="maniCards">
      <div className="container2">
        <Container
          className="mx-auto containerCards"
          style={{ maxWidth: "90%" }}
        >
          <div className="flex cradPadding">
            <div
              onMouseEnter={() => setIsHovering1(true)}
              onMouseLeave={() => setIsHovering1(false)}
              className="cards"
            >
              {!isHovering1 && (
                <div className="topIcon">
                  <img
                    src="https://dojoitsolutions.com/wp-content/uploads/2024/09/Vector-Stroke.svg"
                    alt=""
                  />
                </div>
              )}
              {isHovering1 && (
                <div className="topIcon">
                  <img
                    src="https://dojoitsolutions.com/wp-content/uploads/2024/09/Vectorrr.svg"
                    alt=""
                  />
                </div>
              )}
              <h2>Custom Application Development</h2>
              <p className="p-1">
                Whether you need to build a custom web app from scratch, migrate
                your legacy backend, or streamline existing front-end
                functionality, we can accomplish that efficiently and
                cost-effectively. We offer business intelligence solutions,
                application migration to the cloud, collaboration and portal
                development, document management solutions, secure intranets,
                extranets, portal setup, maintenance, support, and upgrades.
              </p>
              <p className="p-2">Dojo Solutions</p>
              <img
                className="tri01"
                src="https://dojoitsolutions.com/wp-content/uploads/2024/09/Vector0.svg"
                alt=""
              />
              <img
                className="log"
                src="https://dojoitsolutions.com/wp-content/uploads/2024/09/Vector1.svg"
                alt=""
              />
            </div>
            <div
              onMouseEnter={() => setIsHovering2(true)}
              onMouseLeave={() => setIsHovering2(false)}
              className="cards"
            >
              {!isHovering2 && (
                <div className="topIcon">
                  <img
                    src="https://dojoitsolutions.com/wp-content/uploads/2024/09/card2-icon1.svg"
                    alt=""
                  />
                </div>
              )}
              {isHovering2 && (
                <div className="topIcon">
                  <img
                    src="https://dojoitsolutions.com/wp-content/uploads/2024/09/card2-icon2.svg"
                    alt=""
                  />
                </div>
              )}
              <h2>Portal Development</h2>
              <p className="p-1">
                We create web portals and enterprise portals. Our web portals
                offer the best of performance, cost efficiency, and growth
                potential. Our enterprise-level deployments use productive
                intranets, extranets, and engagement workplaces. We combine our
                portals with expansive collaborative capabilities, access to
                aggregated information, self-service workflows, and enterprise
                social functionality.
              </p>
              <p className="p-2">Dojo Solutions</p>
              <img
                className="card2-1"
                src="https://dojoitsolutions.com/wp-content/uploads/2024/09/card2-1.svg"
                alt=""
              />
              <img
                className="card2-2"
                src="https://dojoitsolutions.com/wp-content/uploads/2024/09/card2-2.svg"
                alt=""
              />
              <img
                className="card2-3"
                src="https://dojoitsolutions.com/wp-content/uploads/2024/09/card2-3.svg"
                alt=""
              />
            </div>
            <div
              onMouseEnter={() => setIsHovering3(true)}
              onMouseLeave={() => setIsHovering3(false)}
              className="cards"
            >
              {!isHovering3 && (
                <div className="topIcon">
                  <img
                    src="https://dojoitsolutions.com/wp-content/uploads/2024/09/card3-icon1.svg"
                    alt=""
                  />
                </div>
              )}
              {isHovering3 && (
                <div className="topIcon">
                  <img
                    src="https://dojoitsolutions.com/wp-content/uploads/2024/09/card3-icon2.svg"
                    alt=""
                  />
                </div>
              )}
              <h2>E-Commerce Development</h2>
              <p className="p-1">
                We offer end-to-end eCommerce solutions with payment gateway
                integration, plug-in high-end modules, around-the-clock
                maintenance, and appealing user-interface designs for a
                delightful experience to the end users.
              </p>
              <p className="p-2">Dojo Solutions</p>
              <img
                className="card2-1"
                src="https://dojoitsolutions.com/wp-content/uploads/2024/09/card2-1.svg"
                alt=""
              />
              <img
                className="card2-2"
                src="https://dojoitsolutions.com/wp-content/uploads/2024/09/card2-2.svg"
                alt=""
              />
              <img
                className="card2-3"
                src="https://dojoitsolutions.com/wp-content/uploads/2024/09/card2-3.svg"
                alt=""
              />
            </div>
            <div
              onMouseEnter={() => setIsHovering4(true)}
              onMouseLeave={() => setIsHovering4(false)}
              className="cards"
            >
              {!isHovering4 && (
                <div className="topIcon">
                  <img
                    src="https://dojoitsolutions.com/wp-content/uploads/2024/09/card4-icon1.svg"
                    alt=""
                  />
                </div>
              )}
              {isHovering4 && (
                <div className="topIcon">
                  <img
                    src="https://dojoitsolutions.com/wp-content/uploads/2024/09/card4-icon2.svg"
                    alt=""
                  />
                </div>
              )}
              <h2>Website Development and Maintenance</h2>
              <p className="p-1">
                We create websites with easy navigation, robust information
                architecture, visual cues for users, and action-oriented
                designs. Our services include domain name registration, content
                development and management systems, navigation design,
                programming, user interface design, and much more. Our websites
                work efficiently regardless of screen size or resolution.
              </p>
              <p className="p-2">Dojo Solutions</p>
              <img
                className="card4-1"
                src="https://dojoitsolutions.com/wp-content/uploads/2024/09/card4-1.svg"
                alt=""
              />
              <img
                className="card4-2"
                src="https://dojoitsolutions.com/wp-content/uploads/2024/09/card4-2.svg"
                alt=""
              />
              <img
                className="card4-3"
                src="https://dojoitsolutions.com/wp-content/uploads/2024/09/card4-3.svg"
                alt=""
              />
            </div>
            <div
              onMouseEnter={() => setIsHovering5(true)}
              onMouseLeave={() => setIsHovering5(false)}
              className="cards"
            >
              {!isHovering5 && (
                <div className="topIcon">
                  <img
                    src="https://dojoitsolutions.com/wp-content/uploads/2024/09/card5-icon1.svg"
                    alt=""
                  />
                </div>
              )}
              {isHovering5 && (
                <div className="topIcon">
                  <img
                    src="https://dojoitsolutions.com/wp-content/uploads/2024/09/card5-icon2.svg"
                    alt=""
                  />
                </div>
              )}
              <h2>SaaS Products</h2>
              <p className="p-1">
                We help SaaS startups and enterprises build better software
                products and innovate with scalability. With several SaaS
                development accelerators and reusable frameworks and components,
                we reduce the time to market. We provide UX design and
                engineering, API blueprint and engineering, distributed
                architecture, data analytics, machine learning, cloud-native
                practices, and automated testing.
              </p>
              <p className="p-2">Dojo Solutions</p>
              <img
                className="card5-1"
                src="https://dojoitsolutions.com/wp-content/uploads/2024/09/card5-1.svg"
                alt=""
              />
              <img
                className="card5-2"
                src="https://dojoitsolutions.com/wp-content/uploads/2024/09/card5-2.svg"
                alt=""
              />
            </div>
          </div>
        </Container>
      </div>
    </section>
  );
}
